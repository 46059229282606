import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;

import Popper from "popper.js";
window.Popper = Popper;

import Vue from "vue/dist/vue.esm";

import LookupEvent from "lookup/components/lookup_event.vue";
Vue.component("lookup-event", LookupEvent);

jQuery(function () {
  new Vue({
    el: "#app",
  });
});
