<template>
  <section class="col-12">
    <p v-show="loading">Loading&hellip;</p>
    <transition>
      <section v-show="!loading">
        <p v-if="error">
          Sorry, there was a problem loading the details for this event.
        </p>
        <h3 class="bold" v-if="registrations.length > 0">Orders</h3>
        <section v-for="registration in registrations" class="registration">
          <dl class="row">
            <dt class="col-sm-5">Order Reference</dt>
            <dd class="col-sm-7">
              <a
                :href="registration.registration_url"
                v-if="registration.registration_url"
                target="_blank"
              >
                #{{ registration.ref }}
                <span v-if="registration.test_mode">[TEST]</span>
              </a>
            </dd>
            <dt class="col-sm-5">Placed by</dt>
            <dd class="col-sm-7">{{ registration.name }}</dd>
            <dt class="col-sm-5">Placed on</dt>
            <dd class="col-sm-7">{{ registration.created_at }}</dd>
            <template v-if="registration.ip_address">
              <dt class="col-sm-5">IP Address</dt>
              <dd class="col-sm-7">{{ registration.ip_address }}</dd>
            </template>
            <template v-if="registration.company_name">
              <dt class="col-sm-5">Company</dt>
              <dd class="col-sm-7">{{ registration.company_name }}</dd>
            </template>
            <template v-if="registration.job_title">
              <dt class="col-sm-5">Job Title</dt>
              <dd class="col-sm-7">{{ registration.job_title }}</dd>
            </template>
            <template v-if="registration.billing_address">
              <dt class="col-sm-5">Billing Address</dt>
              <dd class="col-sm-7">{{ registration.billing_address }}</dd>
            </template>
            <template v-if="registration.phone_number">
              <dt class="col-sm-5">Phone Number</dt>
              <dd class="col-sm-7">{{ registration.phone_number }}</dd>
            </template>
            <template v-if="registration.vat_number">
              <dt class="col-sm-5">VAT Number</dt>
              <dd class="col-sm-7">{{ registration.vat_number }}</dd>
            </template>
            <template v-if="registration.payment_method">
              <dt class="col-sm-5">Payment Method</dt>
              <dd class="col-sm-7">{{ registration.payment_method }}</dd>
            </template>
            <dt class="col-sm-5">Receipt</dt>
            <dd class="col-sm-7">
              <a :href="registration.receipt_url" target="_blank"
                >View receipt</a
              >
            </dd>
          </dl>
        </section>
        <h3 class="bold" v-if="tickets.length > 0">Tickets</h3>
        <section v-for="ticket in tickets" class="ticket">
          <dl class="row">
            <dt class="col-sm-5">Ticket Reference</dt>
            <dd class="col-sm-7">
              <a
                :href="ticket.ticket_url"
                v-if="ticket.ticket_url"
                target="_blank"
              >
                #{{ ticket.ref }}
                <span v-if="ticket.test_mode">[TEST]</span>
              </a>
            </dd>
            <dt class="col-sm-5">Ticket</dt>
            <dd class="col-sm-7">{{ ticket.release_title }}</dd>
            <dt class="col-sm-5">Attendee</dt>
            <dd class="col-sm-7">
              <template v-if="ticket.name">
                {{ ticket.name }}
              </template>
              <template v-if="!ticket.name">
                <i>Unassigned</i>
              </template>
            </dd>
            <template v-if="ticket.company_name">
              <dt class="col-sm-5">Company</dt>
              <dd class="col-sm-7">{{ ticket.company_name }}</dd>
            </template>
            <template v-if="ticket.job_title">
              <dt class="col-sm-5">Job Title</dt>
              <dd class="col-sm-7">{{ ticket.job_title }}</dd>
            </template>
            <template v-for="answer in ticket.answers">
              <dt class="col-sm-5">{{ answer.question }}</dt>
              <dd class="col-sm-7" v-if="answer.value">
                {{ answer.value }}
              </dd>
              <dd class="col-sm-7" v-if="answer.url">
                <a :href="answer.url" target="_blank">View file</a>
              </dd>
            </template>
            <!-- <dt class="col-sm-5">Accepted T&Cs</dt>
            <dd class="col-sm-7"><%= link_to "View", "#" %></dd> -->
          </dl>
        </section>
      </section>
    </transition>
  </section>
</template>

<script>
import axios from "axios";

export default {
  props: ["lookupCode", "accountSlug", "eventSlug"],

  data() {
    return {
      loading: true,
      error: false,
      registrations: [],
      tickets: [],
    };
  },

  methods: {
    loadMore: async function () {
      this.page += 1;
    },
  },

  async mounted() {
    const url = `/${this.lookupCode}/${this.accountSlug}/${this.eventSlug}`;
    try {
      const response = await axios.get(url);
      const data = response.data;
      this.registrations = this.registrations.concat(data.registrations);
      this.tickets = this.tickets.concat(data.tickets);
    } catch (error) {
      this.error = true;
    }
    this.loading = false;
  },
};
</script>

<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
